<template>
  <div class="flex h-[100vh] flex-col">
    <!--    <AppHeader />-->
    <main class="relative flex flex-1 flex-col">
      <slot />
    </main>
  </div>
</template>

<script setup lang="ts">
import { useSiteConfigurationStore } from '@/store/siteConfiguration'
import { useUserStore } from '@/store/user'
import { useMagentoCookieState, useAsyncData } from '#imports'

useAsyncData(async () => {
  const userStore = useUserStore()
  if (userStore.loggedIn) {
    const siteConfigurationStore = useSiteConfigurationStore()
    await siteConfigurationStore.getSiteConfiguration(
      useMagentoCookieState().getStore()?.replaceAll('_', ' ') as string,
    )
  }
})
</script>
